import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/agreement.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'
import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'

export default function Agreement({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'利用規約'}
          description={
            'KURORO BLOGを運営するための利用規約詳細ページです。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'agreement/'}
          noIndex={true}
          contentType="article"
        />
        <Header location={locatePath} />

        <h1 className="agreement__h1">KURORO BLOG 利用規約</h1>

        <section className="agreement__section">
          <p>
            KURORO
            BLOGが運営する本サイトの利用について、以下のとおり本利用規約を定めます。本サイトの利用者は、あらかじめ本利用規約に同意した上で、本サイトを利用するものとします。
          </p>
          <h2 className="agreement__h2">第1章 総則</h2>
          <h3 className="agreement__h3">第1条 定義</h3>
          <p>本利用規約において使用する用語は、以下のとおりとします。</p>
          <ol>
            <li>
              「本サイト」とは、そのドメインが「<a href="https://kuroro.blog/">https://kuroro.blog/</a>」である、KURORO
              BLOGが運営するウェブサイト（理由の如何を問わず、KURORO
              BLOGのウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
            </li>
            <li>「利用者」とは、本利用規約の内容に同意した上で、本サイトをご利用する個人を意味します。</li>
            <li>「本契約」とは、本サイトの利用に関する契約を意味します。</li>
            <li>
              「提携事業者」とは、本サイトを提供するにあたりKURORO BLOGと提携した事業者、及びその他KURORO
              BLOGと取引関係、業務委託関係又は提携関係のある事業者を意味します。
            </li>
            <li>「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権を意味します。</li>
            <li>
              「掲載コンテンツ」とは、本サイトに含まれるコンテンツおよび個々の情報、画像、動画、広告等を意味します。
            </li>
          </ol>
          <h3 className="agreement__h3">第2条 本規約の適用範囲</h3>
          <p>
            本利用規約は、利用者とKURORO BLOGとの間における本サイトの利用について生じる全ての法律関係に適用されます。
            KURORO
            BLOGは、本利用規約の定めに従うことに同意することを条件として、利用者に対し、本サイトの利用を許諾いたします。
            当該同意のうえ利用者が本サイトを利用した時点で、利用者とKURORO
            BLOGの間における本契約が成立するものとします。
          </p>
          <h2 className="agreement__h2">第2章 サービス</h2>
          <h3 className="agreement__h3">第3条 本サービスの内容</h3>
          <ol>
            <li>
              本サービスは、本サイトを通じ、利用者に対して「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有を主たる目的としています。
            </li>
            <li>
              本サービスの内容は以下のとおりとします。
              <ol>
                <li>プログラミングに関する情報の掲載・閲覧サービス</li>
              </ol>
            </li>
          </ol>
          <h2 className="agreement__h2">第3章 利用者</h2>
          <h3 className="agreement__h3">第4条 本サービスの利用および設備等</h3>
          <ol>
            <li>利用者は、本サービスのご利用にあたり、本利用規約等を遵守するものとします。</li>
            <li>
              本サービスのご利用は、無料です（但し、本サービスにかかわらず、提携事業者が提供するサービス・商品等別途対価が必要な場合を除きます。）
            </li>
            <li>
              利用者は、本サービスをご利用するために必要な通信機器、ソフトウェア、その他それに付随して必要となる全ての機器（以下、「利用者設備等」といいます）を設置するものとし、本サービスのご利用に関わる一切の通信料・インターネット接続料を負担するものとします。
            </li>
          </ol>
          <h3 className="agreement__h3">第5条 利用者条件</h3>
          <p>
            利用者が、以下のいずれかに該当する場合には、本サービスをご利用いただくことができないものとさせていただきます。
          </p>
          <ol>
            <li>登録情報を提供していただけない方</li>
            <li>
              登録情報のうち、電子メールアドレスについて、ドメイン指定受信等のフィルタを設定されており、KURORO
              BLOGおよび提携事業者から送信される電子メールを受信できない方
            </li>
            <li>
              提携事業者との直接の契約者以外の第三者で代理・代行にて本サービスを利用する方など、本サービスのご利用について、提携事業者との直接の契約者の同意を得ていない方
            </li>
            <li>
              反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者をいいます。）に該当する方、また暴力的行為、詐術・脅迫行為、業務妨害行為等違法行為をされた方。なお、本号に該当する場合、KURORO
              BLOGは、当該利用者との本サービスを含む、一切のサービスの利用停止、その他本サービスに付随する各種契約の解除、もしくは損害賠償請求等必要な措置（法的措置を含みます）を取ることができるものとします。
            </li>
            <li>第7条のいずれかに該当する方</li>
          </ol>
          <h3 className="agreement__h3">第6条 利用者確認の実施</h3>
          <p>
            KURORO
            BLOGは、利用者が本サービスをご利用するにあたり、利用者確認を実施する場合があります。なお、以下のいずれかに該当する場合は、本サービスをご利用できないことがございます。
          </p>
          <ol>
            <li>登録情報に、虚偽の記載、誤記、または入力漏れがあった方</li>
            <li>その他、KURORO BLOGが利用者として不適当と判断した方</li>
          </ol>
          <h2 className="agreement__h2">第4章 利用者の義務</h2>
          <h3 className="agreement__h3">第7条 本サービス利用の削除・停止等</h3>
          <p>
            利用者が以下の事由のいずれかに該当する場合、KURORO
            BLOGは、当該利用者に事前の通知をすることなく、当該利用者の本サービス利用を削除・停止することができます。
          </p>
          <ol>
            <li>利用者が実在しない場合</li>
            <li>利用者が本サービス利用時に虚偽の申告をした場合</li>
            <li>第5条における利用者条件を満たしていない場合</li>
            <li>第9条第1項各号のいずれかに該当する行為をした場合</li>
            <li>その他、KURORO BLOGが利用者として不適当と判断した場合</li>
          </ol>
          <h3 className="agreement__h3">第8条 禁止事項</h3>
          <ol>
            <li>
              全ての利用者に、本サービスを快適にご利用していただくため、利用者が以下の行為を行う事を禁止いたします。
              <ol>
                <li>利用者が本サービス利用時に虚偽の入力をする行為およびそれらのおそれのある行為</li>
                <li>犯罪行為および犯罪行為に結びつく行為およびそれらのおそれのある行為</li>
                <li>公序良俗に反する行為およびそれらのおそれのある行為</li>
                <li>
                  他の利用者、提携事業者またはKURORO
                  BLOGの著作権、財産権、プライバシー、もしくはその他の権利を侵害する行為およびそれらのおそれのある行為
                </li>
                <li>
                  他の利用者、第三者、提携事業者またはKURORO
                  BLOGに不利益もしくは損害を与える行為およびそれらのおそれのある行為
                </li>
                <li>
                  本サービスを通じたもしくは本サービスに関連した営利を目的とする行為およびそれらのおそれのある行為
                </li>
                <li>
                  本サイトまたはそこに含まれる情報等（提携事業者の連絡先等の情報を含みます）の一部または全部を改竄、改変もしくは消去する行為およびそれらのおそれのある行為
                </li>
                <li>
                  本サービスが用いるネットワークシステムの正常な運用を妨害またはネットワークシステムを破壊する行為およびそれらのおそれのある行為
                </li>
                <li>法令に違反する行為およびそれらのおそれのある行為</li>
                <li>上記各号のいずれかに該当する行為を助長する行為およびそれらの行為を助長するおそれのある行為</li>
                <li>上記各号の他、KURORO BLOGが不適切と判断する行為</li>
              </ol>
            </li>
            <li>
              利用者の行為または不作為が本条第1項における禁止事項に該当するか否かは、KURORO
              BLOGの判断によるものとします。
            </li>
            <li>
              利用者の行為または不作為が本条第1項における禁止事項に該当すると判断された場合には、法的手続きの対象となる可能性があります。
            </li>
          </ol>
          <h3 className="agreement__h3">第9条 著作権およびその他の権利</h3>
          <ol>
            <li>
              掲載コンテンツに関する知的財産権およびその他の権利は、KURORO
              BLOGまたは正当な権利を有する権利者に帰属します。
            </li>
            <li>
              利用者は、KURORO
              BLOGまたは正当な権利を有する第三者から適法に許諾を受けた場合および、法令により権利者からの許諾なしに利用または使用が認められている場合を除き、掲載コンテンツの内容を複製、編集、改変、配布、公衆送信その他のあらゆる方法において利用または使用することはできないものとします。
            </li>
            <li>利用者は前項に違反する行為を第三者にさせることはできないものとします。</li>
            <li>
              利用者は、本サービス及び掲載コンテンツ等の全部または一部を改変、改ざんし、または逆コンパイル、逆アセンブル等のリバースエンジニアリングを行ってはならないものとします。
            </li>
            <li>
              前各項の規定にかかわらず、権利者との間で問題が発生した場合、利用者は自己の費用と責任においてかかる問題を解決するものとします。
            </li>
          </ol>
          <h3 className="agreement__h3">第10条 自己責任の原則</h3>
          <ol>
            <li>
              本サービスは無償で提供されるものであり、KURORO
              BLOGの債務は利用者が本サービスを利用できるよう、善良なる管理者の注意義務をもって本サービスを提供し、本サービスを運営することに限られます。
              したがって、本サービスに含まれる情報等の正確性、信頼性、有用性等ならびに本サービスの内容・質については、KURORO
              BLOGは保証いたしかねますので、利用者は自らの責任でサービスをご利用するものとします。
            </li>
            <li>
              利用者は、本サービスのご利用に伴い、提携事業者から問い合わせ等を受けた場合、自己の責任により対応するものとします。
            </li>
            <li>
              利用者は、提携事業者の対応等に対する要望・疑問もしくはクレーム等がある場合は、当該提携事業者に対し、直接その旨申し出るものとし、その結果については自己の責任により対応するものとします。
            </li>
            <li>
              利用者は、KURORO
              BLOGまたは提携事業者に対して損害を与えた場合（利用者が本利用規約等の義務を履行しないことにより、KURORO
              BLOGまたは提携事業者が損害を被った場合を含みます）、自己の責任と費用により賠償するものとします。
            </li>
            <li>
              利用者は、自己の責任と負担により、本サービスのご利用に必要となる利用者設備等の設置ならびに管理を行うものとします。
            </li>
          </ol>
          <h2 className="agreement__h2">第5章 運営</h2>
          <h3 className="agreement__h3">第11条 利用者への告知等</h3>
          <p>
            KURORO
            BLOGは、利用者に対し、本サービスの運営にあたり、本サービス等に関する事項等につき、必要に応じ随時電子メールにより連絡を行うことができるものとします。
          </p>
          <h3 className="agreement__h3">第12条 本サービス内容等の変更および中断・停止</h3>
          <ol>
            <li>
              KURORO
              BLOGは、利用者へ事前に告知または通知することなく本サービスの内容・名称を変更することができるものとします。
            </li>
            <li>
              KURORO
              BLOGは、前項により生じた利用者の損害について、理由の如何を問わず、一切の責任を負わないものとします。
            </li>
          </ol>
          <h3 className="agreement__h3">第13条 本サービスの終了</h3>
          <ol>
            <li>
              KURORO BLOGは、利用者へ事前に告知または通知することなく本サービスを終了することができるものとします。
            </li>
            <li>
              KURORO
              BLOGは、前項により生じた利用者の損害について、理由の如何を問わず、一切の責任を負わないものとします。
            </li>
          </ol>
          <h3 className="agreement__h3">第14条 その他免責</h3>
          <ol>
            <li>本サイトにおける他のホームページへのリンクのご利用に関し、KURORO BLOGは一切の責任を負いません。</li>
            <li>
              KURORO
              BLOGは、利用者設備等の不具合および障害等に起因する通信不良・遅延・データの誤送等による損害について、一切の責任を負わないものとします。
            </li>
          </ol>
          <h2 className="agreement__h2">第6章 個人情報</h2>
          <h3 className="agreement__h3">第15条 個人情報の利用目的と第三者提供</h3>
          <p>
            KURORO BLOGが取得する個人情報の取り扱いについては、別途定める「
            <a href="https://kuroro.blog/privacy/">https://kuroro.blog/privacy/</a>」に従います。
          </p>
          <p>附則 2021年5月11日施行</p>
        </section>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/agreement/',
              text: '利用規約',
            },
          ]}
        />

        <Footer />
      </div>
    </div>
  )
}
